exports.components = {
  "component---src-templates-case-studies-tsx": () => import("./../../../src/templates/CaseStudies.tsx" /* webpackChunkName: "component---src-templates-case-studies-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/CaseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-news-post-tsx": () => import("./../../../src/templates/NewsPost.tsx" /* webpackChunkName: "component---src-templates-news-post-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/News.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-list-tsx": () => import("./../../../src/templates/PostList.tsx" /* webpackChunkName: "component---src-templates-post-list-tsx" */),
  "component---src-templates-post-tag-tsx": () => import("./../../../src/templates/PostTag.tsx" /* webpackChunkName: "component---src-templates-post-tag-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/Pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-redirector-page-tsx": () => import("./../../../src/templates/RedirectorPage.tsx" /* webpackChunkName: "component---src-templates-redirector-page-tsx" */),
  "component---src-templates-styleguide-tsx": () => import("./../../../src/templates/Styleguide.tsx" /* webpackChunkName: "component---src-templates-styleguide-tsx" */),
  "component---src-templates-white-papers-tsx": () => import("./../../../src/templates/WhitePapers.tsx" /* webpackChunkName: "component---src-templates-white-papers-tsx" */)
}

